.mintPage {
  min-height: 100vh;
  background: url("../images/mintBackground.png");
  background-color: #501c55;
  background-size: cover;
}
.backOverlay {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  padding-top: 120px;
  padding-bottom: 60px;
}
.logoCont {
  img {
  }
}
.alignCenter {
  align-items: center;
}
.mintBox {
  padding: 30px;
  background: padding-box,
    linear-gradient(to bottom, #212121, #212121) padding-box,
    linear-gradient(to top, #ca16cb, #37fbfd) border-box;
  border-radius: 25px;
  border: 3px solid transparent;
  width: 50%;
  margin: auto;
  .h1Text {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    h1 {
      color: white;
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 24px;
    }
    .underlined-blue-discord {
      content: "";
      position: absolute;
      width: 80px;
      bottom: 0;
      border-bottom: 4px solid #37fbfd;
    }
  }
  .mint-name {
    text-align: center;
    padding-top: 30px;
    h2 {
      font-size: 21px;
      font-weight: 700;
      color: #fff;
    }
    .mint-price {
      margin-top: -10px;
      p {
        color: #ca16cb;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
  .currentlyMinted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-weight: 600;
    }
    h4 {
      color: #ca16cb;
      padding-top: 13px;
      font-weight: 600;
    }
  }
  .mintCounter {
    .mintCount {
      border: 0 !important;
      margin: 0;

      .mintPrice {
        margin: 5px;
      }
    }
  }

  .mintTitle {
    color: white;
    margin: 20px 0 10px;
    font-size: 16px;

    h1 {
      font-size: 22px;
    }
    h4 {
      margin-top: -3px;
      color: #ca16cb;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .priceTitle {
    color: white;
    margin: 30px 0;
    font-size: 16px;
    text-align: center;

    h1 {
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    h4 {
      margin-top: -3px;
      color: #ca16cb;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .mintName {
    margin: 6px 0;
    border: 2px solid #ca16cb;
    border-radius: 50px;
    display: flex;
    align-items: center;
    button {
      margin: 5px;
      padding: 5px 15px;
      font-size: 20px;
      border-radius: 999px;
      background-color: rgba(0, 0, 0, 0.26);
    }
    h4 {
      color: white;
      width: 100%;
      margin: 0;
    }
    .mintPrice {
      background-color: #8e3fff;
      text-align: center;
      width: 100%;
      padding: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      p {
        color: #ececec;
        margin-top: 0;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0rem;
      }
      &.black {
        p {
          color: black;
        }
      }
    }
  }
  .mintBtn {
    display: flex;
    margin-top: 20px;
    text-align: center;
    justify-content: center;
    .button1 {
      button {
        margin-right: 10px;
        background: none;
        width: 200px;
        height: 40px;
        font-size: 13px;
        color: white;
        border: 4px solid #f59340;
        border-radius: 50px;
        font-weight: 700;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          border: 4px solid #37fbfd;
        }

        &:focus,
        &:active {
          border: 4px solid #ca16cb;
          box-shadow: 0 0 8px 0 #ca16cb, inset 0 0 8px 0 #ca16cb;
          text-shadow: 0 0 10px #fff;
        }
        &:nth-child(1) {
          margin-bottom: 1em;
        }
      }
    }
    .button2 {
      button {
        margin-left: 10px;
        background: none;
        width: 200px;
        height: 40px;
        font-size: 13px;
        color: white;
        border: 4px solid #ca16cb;
        border-radius: 50px;
        font-weight: 700;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          border: 4px solid #37fbfd;
        }

        &:focus,
        &:active {
          border: 4px solid #ca16cb;
          box-shadow: 0 0 8px 0 #ca16cb, inset 0 0 8px 0 #ca16cb;
          text-shadow: 0 0 10px #fff;
        }
        &:nth-child(1) {
          margin-bottom: 1em;
        }
      }
    }
  }
}
#mintNavbar {
  .mintNavbar {
    position: fixed;
    z-index: 99;
    margin: auto;
    width: 100%;
    height: 80px;
    transition: background-color 0.3s ease, top 0.5s;
    display: flex;
    .mintLogo {
      height: 100%;
    }
  }

  #mintNavbar-ul {
    margin-left: auto;
    margin-right: 5px;
    list-style-type: none;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .mintNavbar-ul-element {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    flex: auto;
    text-align: center;
    font: normal normal bold 15px/32px Metropolis;
    color: rgba(255, 255, 255, 0.6);
    padding-left: 40px;
    padding-right: 40px;

    a {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      font-size: 17px;
      color: rgba(255, 255, 255, 0.6);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        width: 15px;
        border-bottom: 3px solid #f59340;
        transition: width 0.5s;
      }

      &:hover {
        color: #fff;

        &::before {
          width: 100%;
        }
      }
    }

    &:hover {
      a {
        color: #fff;

        &::before {
          border-color: #37fbfd;
          width: 100%;
        }
      }
    }
    &:active {
      a {
        color: #fff;
        text-shadow: 0 0 10px white;

        &::before {
          border-color: #f75a60;
          width: 100%;
        }
      }
    }
  }
}
.kiteLogo {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 30px;
  padding-bottom: 10px;
  width: 230px;
}

@media screen and (max-width: 992px) {
  .backOverlay {
    padding-top: 80px;
    flex-direction: column;
  }
  .mintBox {
    width: 100% !important;
    margin: auto;
  }
  .mintName {
    flex-direction: column;
  }
  .mintBtn {
    flex-direction: column;
    .button1 {
      button {
        margin-left: 10px;
      }
    }
    .button2 {
      button {
        margin-right: 10px;
      }
    }
  }
}
.mintWallet h2 {
  margin: 15px 0;
  color: #fff;
  font-size: 18px;
}

button {
  background: none;
  color: white;
  border: 4px solid #5a23a7;
  border-radius: 7px;
  padding: 5px 7px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 4px solid #37fbfd;
  }

  &:focus,
  &:active {
    border: 4px solid #ca16cb;
    box-shadow: 0 0 8px 0 #ca16cb, inset 0 0 8px 0 #ca16cb;
    text-shadow: 0 0 10px #fff;
  }
}

.walletAddress {
  border-radius: 20px;
  border: 2px solid #37fbfd;
  padding: 10px;
  color: white;
  font-weight: 500;
  .walletBtn {
    align-items: center;
    margin: 2px;
    background-color: #37fbfd;
    border-radius: 20px;
    h4 {
      color: #000;
      padding-left: 10px;
    }
    button {
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
    p {
      margin-bottom: 0;
      display: inline-block;
      padding-left: 10px;
    }
    .connected-wallet {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      p {
        overflow: scroll;
      }
      p::-webkit-scrollbar {
        display: none;
      }
      button {
        margin-left: 10px;
        margin-top: 2px;
        margin-bottom: 2px;
        color: #000;
      }
    }
  }

  .p {
    color: #fff;
    margin: 2px;
    margin-bottom: 0rem;
  }
  .kiteLogo {
    display: none;
  }
}
