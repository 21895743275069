// Global settings

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

@import "functions";
@import "~bootstrap/scss/functions";
@import "variables";

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";

$primary-color: #212121;
$secondary-color: #FED963;

@font-face {
  font-family: "Silent Fighter Shadow";
  src: url("../fonts/Silent Fighter Shadow.otf") format("truetype");
}

@font-face {
  font-family: "Silent Fighter";
  src: url("../fonts/Silent Fighter.otf") format("truetype");
}

@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-Regular.ttf");
}
@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-Black.ttf");
  font-weight: 900;
}
@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-Thin.ttf");
  font-weight: 200;
}
@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-RegularItalic.ttf");
  font-style: italic;
}
@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: Metropolis;
  src: url("../fonts/Metropolis-ThinItalic.ttf");
  font-weight: 200;
  font-style: italic;
}


html, body {
  background: $primary-color !important;
  font-family: Metropolis, serif;
  color: white;
  overflow-x: hidden;
}

body {
  margin: 0;
}

a {
  color: white;
}

a:hover {
  color: white;
}

.primary-bg {
  background: $primary-color;
}

.secondary-bg {
  background: $secondary-color;
}

.card {
  padding: 20px 20px;
  border-radius: 32px;
}

button:focus {
  outline: none !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

html {
  overflow-y: overlay;
}
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
