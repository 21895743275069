@import "global.scss";
@import "burger.scss";
@import "mint.scss";
@import "discord.scss";

#navbar {
  .navbar-pc {
    background-color: rgba(93, 24, 117, 0.25);
    position: fixed;
    z-index: 25;
    margin: auto;
    width: 100%;
    height: 110px;
    transition: background-color 0.3s ease, top 0.5s;
    display: flex;

    .logo {
      height: 100%;
      max-width: 70%;
    }
  }

  #navbar-pc-ul {
    margin-left: auto;
    margin-right: 25px;
    list-style-type: none;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .navbar-pc-ul-element {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    flex: auto;
    text-align: center;
    font: normal normal bold 15px/32px Metropolis;
    color: rgba(255, 255, 255, 0.6);
    padding-left: 40px;
    padding-right: 40px;

    a {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        width: 15px;
        border-bottom: 3px solid #f59340;
        transition: width 0.5s;
      }

      &:hover {
        color: #fff;

        &::before {
          width: 100%;
        }
      }
    }

    &:hover {
      background: rgba(61, 29, 68, 0.35);

      a {
        color: #fff;

        &::before {
          border-color: #37fbfd;
          width: 100%;
        }
      }
    }

    &:active {
      background: rgba(61, 29, 68, 0.55);

      a {
        color: #fff;
        text-shadow: 0 0 10px white;

        &::before {
          border-color: #f75a60;
          width: 100%;
        }
      }
    }
  }
}

#header-section {
  padding: 200px 0 300px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../images/hero_background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 27vw), 0 100%);
  }

  .content-box {
    position: relative;
    width: 35%;
    margin-left: 12%;
    background: url("../images/type_texture_hero.png") padding-box,
      linear-gradient(to bottom, #212121, #3d1d44) padding-box,
      linear-gradient(to top, #ca16cb, #37fbfd) border-box;
    border-radius: 25px;
    border: 5px solid transparent;
    z-index: 11;

    .content-box-image {
      display: block;
      margin: 30px auto;
      max-width: 90%;
    }

    .content-box-title {
      font: normal normal bold 45px/60px Metropolis;
      color: #fff;
      max-width: 80%;
      margin: 10px auto;
      text-align: justify;
      position: relative;
      padding-bottom: 40px;
      line-height: 1;

      &.top {
        padding-bottom: 0;
        margin-bottom: 0;
      }

      &.bottom {
        margin-top: 0;
      }
    }

    .content-box-description {
      font-size: 35px;
      color: #fff;
      max-width: 80%;
      margin: 40px auto;
      position: relative;
      opacity: 0.85;
      font-weight: 500;
    }

    .social-icons {
      display: flex;
      width: 80%;
      min-width: 200px;
      justify-content: space-between;
      margin: auto auto 65px;

      .social-icon {
        cursor: pointer;
        width: 10%;
        filter: invert(1);
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }

    .button {
      display: block;
      margin: auto auto 50px;
      font-size: 16px;
    }
  }

  .vr-player {
    position: absolute;
    right: -120px;
    bottom: calc(-10vw + 44em);
    width: 65%;
    pointer-events: none;
    z-index: 11;
  }

  .bottom-square {
    position: absolute;
    bottom: 50px;
    right: -20px;
    width: 50%;
  }

  .kite-texture {
    pointer-events: none;
    position: absolute;
    bottom: -120px;
    left: -50px;
    width: 100%;
    z-index: 10;
  }
}

#about-us-section {
  padding: 750px 60px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../images/VR_NFT_Experience_Background_1.png");
    opacity: 0.5;
    // url("../images/type_texture_vr_nft_experience.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 25vw), 0 100%);
    pointer-events: none;
  }

  .vr-section {
    .container {
      max-width: 100%;
    }

    .vr-item {
      margin: 0 5%;
      padding-bottom: 100px;
      .instruct-img {
        width: 100%;
        position: relative;
        padding-bottom: 100px;
      }
      .vr-title {
        position: relative;
        padding-bottom: 40px;
        color: #fff;
        font-weight: bold;
        font-size: 40px;
      }

      h2 {
        margin-top: 47px;
        color: white;
        line-height: 40px;
        font-weight: 500;
        font-size: 28px;
        opacity: 0.85;
      }

      p {
        margin-top: 30px;
        font-size: 18px;
        line-height: 34px;
        opacity: 0.65;
      }

      button {
        margin-top: 85px;
      }
    }
  }

  .utility-section {
    position: relative;
    margin-top: 100px;

    .container {
      max-width: calc(91.5% - 15px);
      margin: 0 8.5%;
    }
    .NFT_Utility {
      width: 100%;
      position: relative;
      padding-bottom: 100px;
    }
    .utility-title {
      position: relative;
      padding-bottom: 25px;
      color: #fff;
      font-weight: 600;
      display: inline-block;
      font-size: 50px;
    }

    .sub-head {
      .utility-head-item {
        margin: 16px 0 35px;
        display: flex;
        align-items: center;
        font-size: 24px;

        &.last {
          margin-top: 60px;
        }

        span {
          background-color: #f75a60;
          border-radius: 100px;
          width: 15px;
          height: 15px;
          margin-right: 20px;
          flex-shrink: 0;
        }

        p {
          margin: 0;
          font-size: 30px;
          font-weight: 500;
        }
      }

      .utility-subhead-item {
        margin: 10px 0 10px 40px;
        display: flex;
        align-items: center;
        font-size: 18px;

        span {
          background-color: transparent;
          border-radius: 100px;
          border: solid 3px #37fbfd;
          width: 15px;
          height: 15px;
          margin-right: 20px;
          flex-shrink: 0;
        }

        p {
          margin: 0;
          font-size: 21px;
          opacity: 0.65;
        }
      }
    }
  }

  .bottom-string {
    pointer-events: none;
    position: absolute;
    width: 110%;
    bottom: calc(-43vw + 37em);
    left: -5%;
  }
}

#roadmap-section {
  padding-bottom: 775px;
  position: relative;
  margin-top: -480px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../images/Road_Map_Background_1.png");
    opacity: 0.5;
    // url("../images/polygon_texture_road_map.png"),
    // linear-gradient(180deg, #3d1d44 0%, #3b1d41 16.3%, #1f1f1f 100%);
    background-repeat: repeat;
    background-position: right;
    background-size: cover;
    clip-path: polygon(
      0 calc(0.25 * 100vw),
      100% 0,
      100% calc(100% - 0.25vw),
      0 100%
    );
    //background-color: #3C1D42;
  }

  .roadmap-image {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    position: relative;
    width: 100%;
    margin-top: 300px;
  }

  .roadmap-mobile {
    width: 80%;
    margin: 300px auto 0 auto;
  }

  .white-paper-container {
    position: relative;
    font-weight: 600;

    h5 {
      color: white;
      text-align: center;
      font-size: 28px;
      padding: 50px 20%;
      font-weight: 500;
    }

    p {
      color: white;
      text-align: center;
      font-size: 38px;
      padding: 80px 200px;
      font-weight: 600;
    }

    span.blue {
      color: #37fbfd;
      font-weight: normal;
    }
  }

  .roadmap-container {
    position: relative;

    .roadmap-images {
      margin-top: 400px;
      overflow: hidden;

      .roadmap-image-one {
        margin-bottom: 150px;
        margin-left: -20px;
      }

      .roadmap-image-two {
        margin-left: calc(50% - 575px);
        margin-bottom: 150px;
      }

      .roadmap-image-three {
        float: right;
        margin-right: -55px;
      }
    }

    .roadmap-point-items {
      border-width: 3px;
      border-style: solid;
      border-radius: 10px;
      background: rgb(33, 33, 33);
      padding: 10px;

      &.blue {
        border-color: #37fbfd;
      }
    }

    .roadmap-content {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      padding-top: 130px;
    }

    .roadmap-point {
      width: 200px;
      display: inline-block;
      vertical-align: top;
    }

    .roadmap-point-title {
      color: white;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
    }

    ul {
      display: block;
      margin-bottom: 0;
    }

    ul li {
      display: block;
      position: relative;
      font-size: 16px;
    }

    ul li:not(:last-child) {
      margin-bottom: 16px;
    }

    ul li.blue:before {
      content: "";
      position: absolute;
      top: 1.2em;
      left: -30px;
      margin-top: -0.9em;
      background: #37fbfd;
      height: 12px;
      width: 12px;
      border-radius: 50%;
    }
  }

  .button {
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    position: relative;
  }

  .bottom-string {
    pointer-events: none;
    position: absolute;
    width: 110%;
    bottom: calc(-24vw + 37em);
    left: -5%;
    z-index: 4;
  }
}

#team-section {
  padding-bottom: 950px;
  position: relative;
  margin-top: -480px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../images/team_background.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    clip-path: polygon(0 calc(25vw), 100% 0, 100% calc(100% - 25vw), 0 100%);
  }

  .container {
    max-width: 1600px;
    padding: 0 50px;
  }

  .team-member {
    height: 100%;
    display: flex;
    flex-direction: column;

    .team-member-image {
      align-self: center;
      width: 70%;
      transform: translateY(50%);
    }

    .team-member-info {
      background: url("../images/type_texture_team_container.png") padding-box,
        linear-gradient(to bottom, #212121, #3d1d44) padding-box,
        linear-gradient(to bottom, #ca16cb, #37fbfd) border-box;
      border-radius: 25px;
      border: 5px solid transparent;
      padding-top: 125px;
      padding-bottom: 50px;
      display: flex;
      flex-direction: column;
      text-align: center;
      flex-grow: 1;

      h3 {
        color: white;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 75px;
        position: relative;
        padding-bottom: 50px;

        &.orange:before {
          content: "";
          position: absolute;
          width: 20%;
          height: 10px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          border-bottom: 10px solid #f59340;
        }

        &.blue:before {
          content: "";
          position: absolute;
          width: 20%;
          height: 10px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          border-bottom: 10px solid #37fbfd;
        }

        &.purple:before {
          content: "";
          position: absolute;
          width: 20%;
          height: 10px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          border-bottom: 10px solid #ca16cb;
        }
      }

      h4 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 40px;
        padding: 0 30px;
      }

      a {
        margin-top: auto;

        .linkedin-logo {
          filter: invert(1);
          opacity: 40%;
          height: 40px;

          &:hover {
            opacity: 100%;
          }
        }
      }

      .alex-meyer {
        padding: 0 10px !important;
      }
    }
  }

  .kite-texture {
    pointer-events: none;
    position: absolute;
    bottom: calc(-59vw + 40em);
    left: -80px;
    width: 100%;
    z-index: 10;
  }
}

#faq-section {
  padding-bottom: 700px;
  position: relative;
  margin-top: -355px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../images/FAQ_Background_1.png");
    opacity: 0.5;
    // url("../images/polygon_texture_road_map.png"),
    // linear-gradient(0deg, #3d1d44 0%, #3b1d41 16.3%, #1f1f1f 100%);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    clip-path: polygon(0 calc(25vw), 100% 0, 100% calc(100% - 25vw), 0 100%);
    background-color: #3c1d42;
    z-index: 1;
  }

  .container {
    position: relative;
    color: rgba(255, 255, 255, 0.85);
    border-radius: 25px;
    margin: 8px auto;
    max-width: 955px;
    z-index: 2;
  }

  .question-container {
    .question {
      font-size: 28px;
      font-weight: 500;
      padding: 20px 20px 0 80px;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .question::after {
      content: "\002B";
      font-size: 4rem;
      color: #37fbfd;
      position: absolute;
      left: 20px;
      top: -2px;
      transition: 0.2s;
    }

    &.active {
      background: #0006;
      .question {
        color: white;
        &::after {
          transform: rotate(45deg);
          color: #f75a60;
        }
      }
    }

    .answercont {
      max-height: 0;
      overflow: hidden;
      transition: 0.3s;
    }

    .answer {
      padding: 20px 80px 20px 135px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5em;
      white-space: pre-line;
      color: #f59340;
    }

    &:hover {
      .question {
        color: white;
        &::after {
          color: #ca16cb;
        }
      }
      .answer {
        color: rgba(245, 147, 64, 0.65);
      }
      &.active {
        .question {
          color: rgba(255, 255, 255, 0.65);
          &::after {
            color: #8e3fff;
          }
        }
      }
    }
  }

  .title {
    z-index: 2;
  }

  h3 {
    z-index: 2;
    margin-left: 40px;
    color: white;
    margin-top: 105px;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .button {
    z-index: 2;
    margin-left: 40px;
  }

  .string-texture {
    z-index: 2;
    pointer-events: none;
    position: absolute;
    width: 100%;
    bottom: calc(-18.5vw + 2em);
  }
}

#footer-section {
  position: relative;
  margin-top: -20vw;
  padding-bottom: 75px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../images/Footer_Background_1.png");
    opacity: 0.5;
    //url("../images/type_texture_footer.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .container {
    max-width: 90%;
    padding-top: 650px;
  }

  .footer-logo {
    width: 50%;
  }

  .social-icons {
    display: flex;
    width: 90%;
    min-width: 200px;
    justify-content: space-between;
    margin-bottom: 80px;

    .social-icon {
      cursor: pointer;
      width: 8%;
      filter: invert(1);
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  .copyright {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 20px;
    text-shadow: 0 0 10px white;
  }

  .footer-links {
    text-align: left;
    width: fit-content;
    margin: auto;
  }

  .footer-title {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 28px;
    text-shadow: 0 0 15px #ffffff94;
    color: white;
    font-weight: 700;
    border-bottom: 3px solid #ca16cb;
    width: fit-content;
  }

  .footer-ul {
    list-style-type: none;
    padding: 0;
  }

  .footer-element {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 20px;
    position: relative;
    cursor: pointer;
    width: fit-content;
    color: #ffffffbd;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 3px;
      width: 15px;
      border-bottom: 3px solid #ca16cb;
      transition: width 0.5s;
    }

    &:hover {
      color: #ffffff;

      &::before {
        border-color: #37fbfd;
        width: 100%;
      }
    }

    &:active {
      color: #ffffff;
      text-shadow: 0 0 10px #ffffff;
      &::before {
        border-color: #f75a60;
        width: 100%;
      }
    }
  }

  .top-texture {
    pointer-events: none;
    position: absolute;
    width: 40%;
    top: 150px;
    right: 165px;
  }
}

.button {
  background: none;
  color: white;
  border: 4px solid #8e3fff;
  border-radius: 50px;
  padding: 20px 30px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 4px solid #37fbfd;
  }

  &:focus,
  &:active {
    border: 4px solid #ca16cb;
    box-shadow: 0 0 8px 0 #ca16cb, inset 0 0 8px 0 #ca16cb;
    text-shadow: 0 0 10px #fff;
  }
}

.title {
  color: #fff;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  padding-top: 700px;
  padding-bottom: 40px;
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.underlined-pink:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 10px;
  bottom: 0;
  left: 0;
  border-bottom: 10px solid #f75a60;
}

.underlined-purple:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 10px;
  bottom: 0;
  left: 0;
  border-bottom: 10px solid #ca16cb;
}

.underlined-dark-purple:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 10px;
  bottom: 0;
  left: 0;
  border-bottom: 10px solid #8e3fff;
}

.underlined-blue:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 10px;
  bottom: 0;
  left: 0;
  border-bottom: 10px solid #37fbfd;
}

.underlined-orange:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 10px;
  bottom: 0;
  left: 0;
  border-bottom: 10px solid #f59340;
}

.terms-container {
  background: url("../images/type_texture_vr_nft_experience.png");
}

.terms-conditions {
  position: relative;
  padding-top: 90px;
  padding-bottom: 150px;
  z-index: 15;
  color: #eeeeea;

  //ul {
  //  list-style-type: "-    ";
  //}

  h1 {
    color: #eeeeea;
    margin-top: 20px;
    font-weight: bold;
  }

  h2 {
    color: #eeeeea;
    font-weight: 500;
  }

  h3 {
    color: #eeeeea;
    font-weight: 500;
  }
}

@media screen and (max-width: 768px) {
  #header-section {
    .content-box {
      .button {
        font-size: 20px;
        padding: 10px 12px;
      }
    }
  }
  #roadmap-section {
    &::before {
      background-position: center top;
    }
  }
  #team-section {
    .team-member {
      margin-top: -200px;
    }
  }
  #footer-section {
    .footer-logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .copyright {
      text-align: center;
    }

    .social-icons {
      margin: 0 auto 80px auto;
    }
  }
}

@media screen and (max-width: 992px) {
  #navbar {
    #navbar-pc-ul {
      display: none !important;
    }
  }
  #header-section {
    .vr-player {
      display: none;
    }

    .content-box {
      width: 80%;
      z-index: 15;
    }
  }
  #about-us-section {
    .vr-section {
      .vr-item {
        button {
          margin-top: 20px;
          margin-bottom: 80px;
        }
      }
    }
    .utility-section {
      .container {
        margin: 0 17.5%;
        max-width: fit-content;
      }
    }
  }
  #team-section {
    padding-bottom: 550px;
  }
  #faq-section {
    padding-bottom: 350px;
  }
}

@media screen and (min-width: 992px) {
  .bm-burger-button {
    display: none;
  }
}

@media screen and (max-width: 1400px) {
  #navbar {
    .navbar-pc-ul-element {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
