.discordPage {
  min-height: 100vh;
  background: url("../images/discordBackground.png");
  background-size: cover;
  font-family: Metropolis, serif;
  .disField {
    position: relative;
    min-height: 100vh;

    .discordContainer {
      justify-content: center;
      padding: 120px 0;
      text-align: center;
      .discordField {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 999;
        width: 65%;
        margin: auto;
        min-height: calc(100vh - 240px);
      }
      .discordHeading {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1 {
          font-weight: 600;
          font-size: 27px;
          color: #fff;
          margin-bottom: 20px;
        }
        .underlined-blue-discord {
          content: "";
          position: absolute;
          width: 100px;
          bottom: 0;
          border-bottom: 6px solid #37fbfd;
        }
      }
    }
    .discordTexts {
      line-height: 22px;
      padding-top: 30px;
      h2 {
        color: #ffffffbd;
      }
      h3 {
        padding-top: 30px;
        color: #ffffffbd;
      }
    }
    button {
      margin-top: 40px;
      align-self: center;
    }
    .discordFooter {
      position: relative;
      width: 100%;
      height: calc(407 * 100vw / 1200);
      position: absolute;
      bottom: 0;
      img {
        background-size: cover;
        height: 100%;
      }
    }
    .kiteLogo {
      position: absolute;
      bottom: 0;
      right: 0;
      padding-right: 30px;
      padding-bottom: 50px;
      width: 230px;
    }
  }
}
@media screen and (max-width: 820px) {
  .kiteLogo {
    display: none;
  }
  .discordFooter {
    display: none;
  }
  .discordField {
    width: 100% !important;
    margin: auto;
    padding: 0 20px;
  }
}
