/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 55px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: rgba(255, 255, 255, 0.8);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #000000;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: rgb(93, 24, 117);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #fff;
  padding: 0.8em 0;
  font-weight: bold;
  font-size: 33.5px;
}

/* Individual item */
.bm-item {
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  &::before {
    border-width: 5px !important;
  }
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-mint-now-btn {
  padding-top: 4px;
  padding-left: 5px;
  margin: 0 auto;
  vertical-align: middle;
  width: 220px;
  height: 60px;
  background: #fed963 0 0 no-repeat padding-box;
  border: 2px solid #222221;
  color: #222221;
  border-radius: 44px;
  font: normal normal bold 22px/32px Sofia Pro Soft;
  letter-spacing: -1.2px;
  padding-top: 4px;
  padding-left: 5px;
}

.bm-cross {
  background: white;
}
